import { Box, Stack } from '@mui/material';
import { APP, ROUTES } from 'config';
import {
  AutocompleteInput,
  Button,
  DataLossPrompt,
  Footer,
  LabeledValue,
  Loader,
  NothingFoundAlert,
  Panel,
  SelectInput,
} from 'melp-design/components';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useTranslation } from 'react-i18next';
import { usePredefinedToasts } from 'utils/Toast';
import { useRouteParams } from 'utils/useIdParam';
import { useAdminBrands } from 'store/admin-brands';
import {
  AdminBrandCardStatus,
  adminBrandCardStatuses,
  useAdminBrandCard,
  useAdminUpdateBrandCard,
} from 'store/admin-brand-cards';
import { useDebouncedCallback } from 'use-debounce';
import { formatCountry } from 'utils/format';

interface Values {
  brandId: string;
  status: AdminBrandCardStatus;
  countries: string[];
}

const INITIAL_VALUES: Values = {
  brandId: '',
  status: 'enabled',
  countries: [],
};

export const AdminBrandCardSettings: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: cardId } = useRouteParams(ROUTES.admin.cards.settings);
  const predefinedToasts = usePredefinedToasts();

  const [brandSearch, setBrandSearch] = useState('');
  const handleBrandSearch = useDebouncedCallback(setBrandSearch, 300);

  const { data: card, isLoading } = useAdminBrandCard(cardId);
  const { data: brands } = useAdminBrands({
    ...(brandSearch
      ? { search: brandSearch }
      : card?.brand?.id
      ? { brands: [card.brand.id] }
      : {}),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: INITIAL_VALUES,
  });

  useEffect(() => {
    if (card) {
      reset({
        brandId: card.brand?.id ?? '',
        status: card.status,
        countries: card.countries,
      });
    }
  }, [card, reset]);

  const { mutate: updateCard, isLoading: isUpdating } =
    useAdminUpdateBrandCard();

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateCard(
      {
        cardId,
        data: {
          brandId: values.brandId,
          status: values.status,
          countries: values.countries,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  if (isLoading) return <Loader />;
  if (!card) return <NothingFoundAlert />;

  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('common.settings')}>
            <Stack spacing={2}>
              <Controller
                name="brandId"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <AutocompleteInput
                      label={t('brands.title')}
                      name={name}
                      value={value}
                      multiple={false}
                      required
                      onChange={onChange}
                      options={
                        brands?.items.map((brand) => ({
                          label: `${brand.name} (${t('marketplace.employeeID', {
                            id: brand.sequence,
                          })})`,
                          value: brand.id,
                        })) ?? []
                      }
                      onInputChange={handleBrandSearch}
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="status"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      label={t('common.status')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={adminBrandCardStatuses.map((status) => ({
                        label: t(`status.${status}`),
                        value: status,
                      }))}
                      required
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="countries"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <AutocompleteInput
                    label={t('marketplaceItems.countries')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={APP.countries.alpha3.map((alpha3) => ({
                      value: alpha3,
                      label: formatCountry(i18n.language, alpha3),
                    }))}
                    required
                    multiple
                    ref={ref}
                  />
                )}
              />

              <LabeledValue
                label={t('cards.provider_id.label')}
                value={card.externalId}
              />
            </Stack>
          </Panel>

          <Panel title={t('menu.integrations')}>
            <Stack gap={2}>
              <LabeledValue
                label={t('integrations.name')}
                value={card.provider}
              />

              {card.provider !== 'melp' ? (
                <LabeledValue
                  label="Raw"
                  value={
                    <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                      <pre>{JSON.stringify(JSON.parse(card.raw), null, 2)}</pre>
                    </Box>
                  }
                />
              ) : null}
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              type="submit"
              disabled={isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isUpdating} />
    </>
  );
};
