import { IAdminBaseBrand, IAdminBrand } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { convertUploadedFile } from 'store/files';
import { loaders } from './admin-brands.loaders';
import { AdminBaseBrand, AdminBrand } from './admin-brands.types';

const convertBaseBrand = (brand: IAdminBaseBrand): AdminBaseBrand => {
  return {
    id: brand.id,
    sequence: brand.sequence,
    name: brand.name,
    slug: brand.slug,
    status: brand.status,
    variant: brand.type,
    primaryCategory: brand.primaryCategory ?? '',
    priorityIndex: brand.priorityIndex,
    providers: brand.providers,
    countries: brand.countries,
    enabledCards: brand.enabledCards,
    discountsCount: brand.discountsCount,
    ordersCount: brand.ordersCount,
  };
};

const convertBrand = (brand: IAdminBrand): AdminBrand => {
  return {
    ...convertBaseBrand(brand),
    secondaryCategory: brand.secondaryCategory ?? '',
    searchTags: brand.searchTags,
    logo: brand.logo
      ? convertUploadedFile(brand.logo)
      : brand.logoHref
      ? { id: '', mime: '', name: '', url: brand.logoHref }
      : null,
    content: brand.content.length
      ? brand.content.map((lang) => ({
          language: lang.language,
          description: lang.description,
          shortDescription: lang.shortDescription,
        }))
      : brand.language
      ? [
          {
            language: brand.language,
            description: brand.description ?? '',
            shortDescription: brand.shortDescription ?? '',
          },
        ]
      : [],
    createdAt: brand.createdAt,
    updatedAt: brand.updatedAt ?? null,
  };
};

const convertBrands = (brands: IAdminBaseBrand[]): AdminBaseBrand[] => {
  return brands.map(convertBaseBrand);
};

export const converters = {
  getBrands: ({ data }: Awaited<ReturnType<typeof loaders.getBrands>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertBrands(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getBrand: ({ data }: Awaited<ReturnType<typeof loaders.getBrand>>) => {
    return convertBrand(data);
  },
};
